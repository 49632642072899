.log {
  line-height: 1.7;
  transform: translateZ(0);

  .log-group-title {
    color: red;
    font-weight: bold;
  }

  .log-group-body {
    border-left-color: #757575;
    padding-left: 9px;
    margin-left: 3px;
    border-left-width: 1px;
    border-left-style: dotted;
  }
}
