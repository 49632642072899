.action {
  padding: 16px;
  background: white;
  border-radius: 6px;
  margin: 16px;
  max-width: 640px;
  line-height: 1.7;

  .actions {
    display: flex;
    flex-direction: column;
    max-height: 360px;
    overflow-y: auto;

    @media screen and (min-width: 720px) {
      max-height: 240px;
    }

    & > :not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .action-title {
    font-size: 175%;
    line-height: 1.7;
    font-weight: 600;
    margin-bottom: 16px;
    text-align: center;
  }

  img {
    max-height: 320px;
    object-fit: cover;
    width: 100%;
  }
}

@media screen and (min-width: 720px) {
  .action {
    padding: 32px;
  }
}
