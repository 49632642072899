.menu {
  padding: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;

  @media screen and (min-width: 720px) {
    padding: 32px;
  }

  a {
    color: white;
  }

  .legal {
    font-size: 12px;
    max-width: 600px;
    margin-left: auto;

    .contact {
      font-size: 14px;
    }
  }

  .options {
    margin-top: 32px;
    margin-bottom: 32px;

    & > * {
      &:not(:last-child) {
        margin-bottom: 32px;

        @media screen and (min-width: 720px) {
          margin-top: 48px;
          margin-bottom: 48px;
        }
      }

      display: flex;
      justify-content: center;
      flex-direction: column;

      @media screen and (min-width: 720px) {
        display: block;
      }
    }

    button {
      min-width: 160px;
    }

    .hint {
      color: #b1b1b1;
      margin-top: 8px;
      font-size: 14px;
      text-align: center;
      display: none;

      @media screen and (min-width: 720px) {
        text-align: left;
        display: block;
      }
    }

    .requirement {
      color: #b1b1b1;
      margin-top: 8px;
      font-size: 14px;
      text-align: center;

      @media screen and (min-width: 720px) {
        text-align: left;
      }
    }
  }

  .status-bar {
    justify-content: flex-start;
    padding: 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .leaderboards {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));

    .entry-name {
      font-weight: bold;
    }

    & > :not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .leaderboard-entries {
    margin-bottom: 8px;

    & > :not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .progress-list {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(136px, 1fr));

    .progress-box {
      .progress-title {
        font-size: 12px;
        text-transform: uppercase;
      }

      .progress-value {
        font-size: 150%;
        font-weight: bold;
      }
    }
  }

  section + section {
    margin-top: 32px;
  }

  h4 {
    margin-top: 16px;
    margin-bottom: 8px;
    text-transform: uppercase;
    font-weight: 500;
  }

  .legal {
    margin-top: 32px;
  }
}

.backdrop {
  background: black;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  pointer-events: none;
  transform: translateZ(0);

  &.active {
    pointer-events: initial;
    opacity: 1;
  }
}

header {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.logo-header {
  h1 {
    margin-bottom: 0;
    margin-top: 0;
    display: flex;
    align-items: center;
    line-height: 40px;
    font-size: 30px;

    @media screen and (min-width: 720px) {
      line-height: 24px;
      font-size: 32px;
    }
  }

  h3 {
    margin-top: 8px;
    margin-bottom: 0;
    font-weight: normal;
  }

  .logo {
    height: 64px;
    color: white;
    fill: white;
    margin-right: 8px;
    animation: spin 6s linear infinite;
  }

  .logo-container {
    margin-right: 8px;
    position: relative;
    display: flex;
  }

  .logo-inner {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 12px;
    top: 12px;
  }
}

.legal-details {
  padding-top: 8px;

  p,
  ul {
    margin-bottom: 0;
    margin-top: 0;
  }
}
