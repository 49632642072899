.layer {
  transform: translateZ(0);
}

.well {
  width: 100%;
  margin: 0 -16px;
  width: calc(100% + 32px);
  display: inline-block;

  video {
    width: 100%;
    height: auto;
  }
}

@media screen and (min-width: 720px) {
  .well {
    width: calc(100% + 64px);
    margin: 0 -32px;
  }
}

button,
a.button {
  min-height: 36px;
  min-width: 96px;
  border: 1px solid #0087e2;
  box-sizing: border-box;
  font-size: inherit;
  font-style: normal;
  font-weight: 400;
  height: 40px;
  line-height: 1.5em;
  max-width: 100%;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  color: #fff;
  background: #0052cc;
  border-radius: 3px;
  border-width: 0;
  margin: 0;
  padding: 0 12px;
  text-decoration: none;
  transition: background 0.1s ease-out 0s;
  outline: none !important;
  cursor: pointer;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  flex-shrink: 0;

  &:hover:not([disabled]) {
    background: #0065ff;
  }

  &[disabled] {
    background: #555a62;
    cursor: not-allowed;
  }
}

.event,
.action,
.media {
  img {
    width: auto;
    height: auto;
  }
}

.pigeon-attribution {
  display: none !important;
}

.status-bar {
  display: flex;
  padding: 16px;
  justify-content: center;

  & > :not(:last-child) {
    margin-right: 16px;
  }
}

@keyframes statusChange {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  10% {
    transform: translateY(0px);
    opacity: 1;
  }
  90% {
    transform: translateY(-0px);
    opacity: 1;
  }
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

.status-row {
  display: flex;

  .status-row-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .status-row-label {
    display: none;
  }

  & > :not(:last-child) {
    margin-right: 8px;
  }

  svg {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    min-width: 20px;
    min-height: 20px;
    max-width: 20px;
    max-height: 20px;
  }

  &.infected {
    svg {
      color: #ffa000;
    }
  }

  &.dead {
    svg {
      color: #e64a19;
    }
  }

  &.recovered {
    svg {
      color: #388e3c;
    }
  }
}

.stats {
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }
}

.stats-row {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 260px;
  transition: color 0.5s cubic-bezier(0.6, -0.28, 0.74, 0.05) 0s;

  & > :not(:last-child) {
    margin-bottom: 2px;
  }

  svg {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    min-width: 20px;
    min-height: 20px;
    max-width: 20px;
    max-height: 20px;
    display: none;
  }

  &.free {
    color: lightgray;
  }

  &.active {
    color: #388e3c;
  }

  .progress-bar {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    padding: 2px;
    grid-column-gap: 2px;
    height: 16px;
    border-radius: 2px;
    width: 181px;
    background-color: rgba(0, 0, 0, 0.1);
    transition: background-color 0.5s cubic-bezier(0.6, -0.28, 0.74, 0.05) 0s;

    .cell {
      background-color: #388e3c;
      border-radius: 2px;
      opacity: 1;
      transform: scale(1);
      transition: opacity 0.5s cubic-bezier(0.6, -0.28, 0.74, 0.05) 0s,
        transform 0.5s ease-out 0s;
      transition-delay: 1.5s;

      &.empty {
        opacity: 0;
        transform: scale(0);
      }
    }
  }

  .stats-row-header {
    display: flex;
    justify-content: space-between;

    & > :not(:last-child) {
      margin-right: 8px;
    }

    .diff {
      margin-left: 6px;
      width: 0;
      display: inline-block;
      color: red;
      font-size: 14px;
      animation: statusChange 5s forwards;

      &.positive {
        color: #388e3c;
      }
    }
  }

  &.empty {
    color: red;

    .progress-bar {
      background: rgba(255, 0, 0, 0.1);
    }
  }
}

.App {
  color: white;
  max-height: 100vh;
}

.panel {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #252526;
  padding: 16px;
  width: 320px;

  & > * {
    margin-bottom: 16px;
  }
}

.container {
  background: #252526;
  padding: 16px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transform: translateZ(0);

  .recharts-wrapper {
    margin-bottom: -8px;
  }

  @media screen and (max-width: 719px) {
    z-index: 100;
    width: auto;
    box-sizing: content-box;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    overflow-y: auto !important;

    .recharts-wrapper {
      max-width: calc(100vw - 64px);
      overflow: hidden;
    }
  }
}

.attribution {
  padding: 16px;
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: 13px;

  a {
    color: white;
    text-decoration: none;
    font-weight: 600;
  }

  @media screen and (min-width: 720px) {
    bottom: 8px;
  }
}

.controls {
  color: white;
  position: fixed;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 72px;
  transform: translateZ(0);

  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    position: relative;
    top: 2px;
    margin-left: 4px;
  }

  @media screen and (min-width: 720px) {
    bottom: 12px;
    left: auto;
    right: 8px;
    text-align: right;
  }

  a {
    color: white;
    text-decoration: none;
    font-weight: 600;
    margin-right: 8px;
  }
}

.container.chart {
  position: fixed;
  bottom: 32px;
  right: 0;
  margin: 16px;
  max-width: 640px;
  border-radius: 6px;
  overflow: hidden;
}

.container.statistics {
  position: fixed;
  top: 0;
  right: 0;
  margin: 16px;
  max-width: 640px;
  border-radius: 6px;
  overflow: hidden;
}

.container.logs {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 16px;
  max-width: 360px;
  border-radius: 6px;
  overflow: hidden;
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

.status-header {
  transform: translateZ(0);
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  button {
    max-width: 320px;
    margin-top: 16px;
  }
}

.recharts-default-tooltip {
  background: #252526 !important;
}

.leaflet-container {
  height: 400px;
  width: 100%;
}

.map {
  position: absolute;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  filter: brightness(1.5);
  transform: translateZ(0);
}

html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  line-height: 1.7;
}

.map > div {
  background-color: #262626 !important;
}

.logo-box {
  position: absolute;
  top: 32px;
  left: 32px;
  transform: translateZ(0);

  @media screen and (min-width: 720px) {
    line-height: 24px;
  }
}

@media screen and (max-width: 719px) {
  .logo-box.gameplay {
    top: 16px;
    left: 16px;
    transform-origin: 0 0;
    transform: scale(0.75);

    .tagline {
      display: none;
    }
  }
}

.alert {
  padding: 4px 16px;
  border: 1px solid red;
  border-radius: 64px;
  color: red;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.positive {
    border-color: #388e3c;
    color: #388e3c;
  }

  svg {
    margin-right: 4px;
  }
}

.container-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  h3 {
    margin: 0;
    margin-left: 16px;
  }

  svg {
    padding: 16px;
    margin: -16px;
    cursor: pointer;
  }
}

@media screen and (min-width: 720px) {
  .container-header {
    display: none;
  }
}

@media screen and (max-width: 719px) {
  .container:not(.active) {
    display: none;
  }

  .stats-row {
    max-width: none;
  }

  .container {
    max-height: none !important;
    max-width: none !important;
  }

  .attribution {
    margin-bottom: 56px;
  }

  button {
    white-space: normal;
    height: auto;
    padding: 8px 16px;
    box-sizing: border-box;
    max-height: none;
  }

  .status-header {
    padding: 0;
    padding-top: 56px;
    white-space: nowrap;
  }
}

.app-bar {
  transform: translateZ(0);
  background: #252526;
  padding: 16px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  min-height: 56px;
  font-weight: bold;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 0;
  align-items: center;
  cursor: pointer;

  & > * {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 16px;
  }
}

@media screen and (min-width: 720px) {
  .app-bar {
    display: none;
  }
}

.busy-status {
  margin-top: 8px;
}

.music-toggle {
  cursor: pointer;
}

.tabs {
  margin-bottom: 12px;
  margin-top: -6px;

  & > :not(:last-child) {
    margin-right: 8px;
  }

  .tab {
    color: white;
    text-decoration: none;
    font-weight: 600;
    transition: opacity 0.2s linear;

    &:not(.active) {
      opacity: 0.5;
    }
  }
}

.action-buttons {
  display: flex;

  & > :not(:last-child) {
    margin-right: 8px;
  }

  .fast-forward-button {
    padding: 0;
    min-width: 40px;

    svg {
      transform: scale(0.75);
      fill: white;
      stroke: transparent;
    }
  }
}

.extra-actions {
  margin-bottom: 16px;

  .button {
    display: flex;
  }

  & > :not(.last-child) {
    margin-bottom: 8px;
  }
}

#leaderboards-name {
  display: flex;
  width: 100%;
  padding: 8px;
  border: 1px solid lightgray;
  border-radius: 6px;
  margin-top: 4px;
  font-size: 16px;
  box-sizing: border-box;
  margin-bottom: 16px;
}
