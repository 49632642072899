@keyframes slide {
  100% {
    left: -1700px;
  }
}

@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}

@keyframes advanced {
  0%,
  25% {
    transform: translateX(0%);
    left: 0%;
  }
  75%,
  100% {
    transform: translateX(-100%);
    left: 100%;
  }
}

.media {
  padding: 0;
  background: black;
  border-radius: 6px;
  margin: 16px;
  max-width: 960px;
  line-height: 1.7;

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    animation: fadeIn 0.5s forwards;
    animation-delay: 1.2s;
    transition: backdrop-filter 0.25s ease-in-out;
    will-change: opacity backdrop-filter;
    cursor: pointer;

    & > :not(:last-child) {
      margin-bottom: 8px;
    }

    &:hover {
      backdrop-filter: blur(5px);
    }

    button {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
  }

  .action-title {
    font-size: 175%;
    line-height: 1.7;
    font-weight: 600;
    margin-bottom: 16px;
    text-align: center;
  }

  video,
  img {
    width: 100%;
    height: 100%;
    margin: 0;
    object-fit: cover;
    border-radius: 6px;
    overflow: hidden;
    transition: opacity 0.25s ease-in-out;
  }

  .media-cover {
    position: relative;
    display: flex;
    font-size: 1.5625vw;

    @media screen and (min-width: 1024px) {
      font-size: 16px;
    }

    .actions {
      font-size: 16px;
    }

    video,
    .cover-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
    }
  }

  .strip {
    position: absolute;
    top: 84.8%;
    left: 26.8%;
    right: 0;
    color: white;
    /* left: 0; */
    /* bottom: 10%; */
    background: #860114fa;
    opacity: 1;
    height: 5%;
    text-transform: uppercase;
    padding-left: 1%;
    font-size: 120%;
    line-height: 140%;
    opacity: 1;
    letter-spacing: -0.5px;
    filter: blur(0.4px);
    opacity: 1;
    white-space: nowrap;
    overflow: hidden;

    .strip-content {
      position: relative;
      animation: 3s linear 0s infinite alternate advanced;
      will-change: transform left;
      animation-delay: 3s;
      width: fit-content;
      padding-right: 16px;
    }
  }

  .strip-primary {
    position: absolute;
    top: 90.2%;
    left: 18.4%;
    right: 11.9%;
    color: white;
    /* left: 0; */
    /* bottom: 10%; */
    background: white;
    color: #0c3b8f;
    opacity: 1;
    height: 6%;
    /* text-transform: uppercase; */
    padding-left: 1%;
    font-size: 120%;
    line-height: 160%;
    opacity: 1;
    /* letter-spacing: -0.5px; */
    filter: blur(0.5px);
    opacity: 1;
    /* background: pink; */
    /* opacity: 0.5; */
    white-space: nowrap;
    overflow: hidden;
    opacity: 0;
    font-weight: 700;
    opacity: 1;
    backdrop-filter: blur(10px);
  }

  .strip-animated {
    left: 0;
    position: relative;
    animation: slide 25.5s forwards;
    animation-timing-function: linear;
    will-change: left;
  }

  .cover-image {
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    will-change: opacity;
    cursor: pointer;

    &.active {
      opacity: 1;
    }
  }

  .video-player {
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    transition-delay: 0.1s;
    will-change: opacity;

    &.active {
      opacity: 1;
    }
  }
}
